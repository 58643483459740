<template>
  <div class="px-5">
    <Form
      title="Editar categoría"
      ref="academyCategoryForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
      :deletable="true"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      response: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/academy_category",
          module: "academyCategory",
          id: this.$route.params.id2
        })
        .then((response) => {
          this.response = { ...response.data }
          let formx = response.data
          this.$refs.academyCategoryForm.form = formx
          this.$refs.academyCategoryForm.form.category = formx.id_category + "$$" + formx.id_subcategory

          this.$refs.academyCategoryForm.visible =
            response.data && response.data.active === 1 ? true : false

          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    toggleVisibility() {
      this.$store
        .dispatch("global/changeStatus", {
          payload: {
            id: this.$refs.academyCategoryForm.form.id,
            status: !this.$refs.academyCategoryForm.visible ? 1 : 0
          },
          route: "/academy_category",
          module: "academyCategory"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-status", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = { ...this.$refs.academyCategoryForm.form }
      this.loading = true

      form.id_category = form.category.split("$$")[0]
      form.id_subcategory = form.category.split("$$")[1]

      this.$store
        .dispatch("global/update", {
          payload: {
            id: form.id,
            id_category: form.id_category,
            id_subcategory: form.id_subcategory,
            id_coach: form.id_coach,
            url_picture: form.url_picture,
          },
          route: "/academy_category/update",
          module: "academyCategory"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-update", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/academias/"+this.$route.params.id)
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
